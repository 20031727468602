import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  ImageListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Logout from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";

function HomeNavbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLogin, setIsLogin] = React.useState(true);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userData");
    localStorage.removeItem("auth");
    navigate("/");
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "6vh",
      }}
    >
      {/* <CssBaseline /> */}
      <AppBar
        component="nav"
        sx={{
          backgroundColor: "white",
          color: "teal",
          height: "6vh",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid container display="flex" alignItems="center">
          <Grid item display="flex" sx={{ alignItems: "center" }}>
            <ImageListItem sx={{ margin: "5px 10px 5px 15px" }}>
              <img
                src="/assets/favicon.png"
                alt="Logo"
                style={{ height: "40px", width: "40px" }}
              />
            </ImageListItem>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "24px",
              }}
            >
              ToDo-App
            </Typography>
          </Grid>

          <Grid item sx={{ position: "fixed", right: 10 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                margin: "2px",
                gap: 2,
              }}
            >
              <Button
                sx={{
                  px: 3,
                  borderRadius: "20px",
                  "@media screen and (max-width: 500px)": {
                    display: !isLogin ? "" : "none",
                    px: 1,
                  },
                }}
                variant="outlined"
                onClick={() => {
                  setIsLogin(!isLogin);
                  navigate("/signup");
                }}
              >
                Sign Up
              </Button>
              <Button
                sx={{
                  px: 3,
                  borderRadius: "20px",
                  "@media screen and (max-width: 500px)": {
                    display: isLogin ? "" : "none",
                    px: 1,
                    mr: 1,
                  },
                }}
                variant="contained"
                onClick={() => {
                  setIsLogin(!isLogin);
                  navigate("/login");
                }}
              >
                Login
              </Button>
            </Box>
          </Grid>
        </Grid>
      </AppBar>
    </Box>
  );
}

export default HomeNavbar;
