import React from "react";
import HomeNavbar from "./TODO_Module/HomeNavbar";
import { Outlet } from "react-router-dom";

function Home() {
  return (
    <div>
      <HomeNavbar />
      <Outlet />
    </div>
  );
}

export default Home;
