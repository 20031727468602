import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Modal,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  tableSortLabelClasses,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import toDoServices from "../Services/ToDoServices";
import { Link, Divider } from "@mui/material";
import {
  AddBoxRounded,
  ConstructionOutlined,
  Logout,
} from "@mui/icons-material";
import { useAsyncError, useNavigate } from "react-router-dom";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import GroupsIcon from "@mui/icons-material/Groups";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import groupServices from "../Services/GroupServices";
import CustomTextField from "../GenericComponents/CustomTextField";
import TaskInfo from "./TaskInfo";
import TaskStatus from "../Enums/TaskStatus";
import { tab } from "@testing-library/user-event/dist/tab";
import userServices from "../Services/UserServices";
import GroupInfo from "./GroupInfo";

const tabStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  fontWeight: "bold",
  color: "black",
  textTransform: "capitalize",
  width: "100%",
  "&.Mui-selected": {
    backgroundColor: "lightgrey",
    transition: "0.5s",
    borderRight: 2.5,
  },
  py: 0,
  minHeight: "35px",
};

function ToDo_Body({ userData, openMenu, onTabChange }) {
  const navigate = useNavigate();

  const [activeGroups, setActiveGroups] = useState([]);
  const [inActiveGroups, SetInActiveGroups] = useState([]);
  const [userList, setUserList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [taskRender, setTaskRender] = useState(false);
  const [groupRender, setGroupRender] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showGroupInfo, setShowGroupInfo] = useState(false);

  const memberProps = {
    options: memberList,
    getOptionLabel: (option) => option.email,
  };

  const userProps = {
    options: userList,
    getOptionLabel: (option) => option.email,
  };

  // State for all tasks
  const [tasks, setTasks] = useState([]);
  // State for current task
  const [task, setTask] = useState(null);
  // State for task title in modal
  const [title, setTitle] = useState("");
  // State for task description in modal
  const [description, setDescription] = useState("");
  // State for modal visibility
  const [open, setOpen] = useState(false);
  // State for creating or editing a task
  const [isCreateAction, setIsCreateAction] = useState(false);

  const [triggerEffect, setTriggerEffect] = useState(false);

  //team details
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [teamName, setTeamName] = useState("");

  const [tabValue, setTabValue] = useState(0);

  const validateTabLabel = (label) => {
    const maxLength = 15;
    if (label.length > maxLength) {
      return label.substring(0, maxLength) + "...";
    }
    return label;
  };

  const handleChangeTab = (e, v) => {
    setTabValue(v);
  };

  const handleOpenTeamModal = () => {
    setOpenTeamModal(true);
  };

  const handleCloseTeamModal = () => {
    setOpenTeamModal(false);
    // Reset team member details if needed
    setTeamName("");
    setIsDisabled(true);
    setSelectedUsers([]);
  };

  const handleEnterTeamName = (e) => {
    setTeamName(e.target.value);
  };

  // Function to handle opening modal
  const handlePlusButtonClick = (e) => {
    setIsCreateAction(true);

    setOpen(true);
  };

  // Function to handle closing modal
  const handleCloseModal = (e) => {
    setOpen(false);
    setDescription("");
  };

  // Function to handle typing in task title input
  const handleEnterTaskTitle = (e) => {
    setTitle(e.target.value);
  };

  // Function to handle typing in task description input
  const handleEnterTaskDescription = (e) => {
    setDescription(e.target.value);
  };

  async function updateTask() {
    const currentTimeStamp = new Date();
    const currentDate = currentTimeStamp.toISOString().split("T")[0];
    try {
      console.log(task);
      let data = await toDoServices.updateTask({
        id: task.id,
        title: title,
        description: description,
        status: task.status,
        date: currentDate,
        userId: task.groupId === null ? task.userId : selectedMember.id,
        groupId: task.groupId,
      });
      console.log(data);
      if (data === false) {
        console.log("Something went wrong.");
      } else {
        if (data.code === 200) {
          console.log("Task edited successfully ", data.responseBody);
        }
      }
    } catch (error) {
      console.log("ERROR");
    }
  }

  // Function to handle edit a task
  const handleUpdateTaskButtonClick = (e) => {
    updateTask();
    setTriggerEffect(!triggerEffect);
    setOpen(false);
  };

  async function addTask() {
    const currentTimeStamp = new Date();
    const currentDate = currentTimeStamp.toISOString().split("T")[0];

    try {
      let data = await toDoServices.addTask({
        title: title,
        description: description,
        date: currentDate,
        userId: tabValue === 0 ? userData.id : selectedMember.id,
        groupId: tabValue === 0 ? null : tabValue,
      });

      if (data === false) {
        console.log("Something went wrong.");
      } else {
        if (data.code === 200) {
          console.log("Task added successfully ", data.responseBody);
        }
      }
    } catch (error) {
      console.log("ERROR");
    }
  }

  // Function to handle creating a new task
  const handleCreateTaskButtonClick = (e) => {
    addTask();
    setOpen(false);
    setTitle("");
    setDescription("");
  };

  const getTasks = async () => {
    if (tabValue === 0) {
      const taskData = await toDoServices.getPersonalTasks(userData.id);
      if (taskData.code === 200) {
        console.log("GET  RESPONSE BODY ", taskData.responseBody);
        const list = taskData.responseBody;
        const sortedList = list.sort(
          (a, b) =>
            (a.status === TaskStatus.DONE ? 1 : -1) -
            (b.status === TaskStatus.DONE ? 1 : -1)
        );
        setTasks(sortedList);
      } else {
        console.log("ERROR");
      }
    } else {
      const taskData = await toDoServices.getGroupTasks(tabValue);
      if (taskData.code === 200) {
        console.log("GET RESPONSE BODY ", taskData.responseBody);
        const list = taskData.responseBody;
        const sortedList = list.sort(
          (a, b) =>
            (a.status === TaskStatus.DONE ? 1 : -1) -
            (b.status === TaskStatus.DONE ? 1 : -1)
        );
        setTasks(sortedList);
      } else {
        console.log("ERROR");
      }
    }
  };

  const getGroups = async () => {
    console.log(JSON.parse(localStorage.getItem("auth")).username);
    let data = await groupServices.getGroupsByUserId(userData.id);
    if (data === false) {
      console.log("Something went wrong while getting groups.");
    } else {
      console.log(data.code);
      if (data.code === 200) {
        setActiveGroups(data.responseBody.activeGroups);
        SetInActiveGroups(data.responseBody.inActiveGroups);
        setGroupRender(true);
      } else {
        console.log("Failed to retrieve groups.");
      }
    }
  };

  const getAllUsers = async () => {
    let data = await userServices.getAllUsers();
    if (data === false) {
      console.log("Something went wrong  while getting users.");
    } else {
      console.log(data.code);
      if (data.code === 200) {
        setUserList(data.responseBody);
        setTaskRender(true);
      } else {
        console.log("Failed to retrieve groups.");
      }
    }
  };

  const createGroup = async () => {
    const memberIds = selectedUsers.map((user) => user.id);
    memberIds.unshift(userData.id);

    let data = await groupServices.createGroup({
      name: teamName,
      ownerId: userData.id,
      memberIds: memberIds,
    });
    if (data === false) {
      console.log("Something went wrong.");
    } else {
      console.log(data.code);
      if (data.code === 200) {
        setOpenTeamModal(false);
      } else {
        console.log("Failed to create groups.");
      }
    }
  };

  useEffect(() => {
    setIsDisabled(
      teamName ? (selectedUsers.length !== 0 ? false : true) : true
    );
  }, [teamName, selectedUsers]);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    console.log(isLoggedIn);
    if (!isLoggedIn) {
      navigate("/login");
    }

    getGroups();
    getAllUsers();
  }, [triggerEffect]);

  useEffect(() => {
    onTabChange();
  }, [tabValue]);

  useEffect(() => {
    if (tabValue !== -1) {
      getTasks();
      if (tabValue !== 0) {
        setMemberList(
          activeGroups.find((group) => group.id === tabValue).members
        );
      }
    }
  }, [open, task, triggerEffect, tabValue]);

  const restoreGroup = async (groupId) => {
    let data = await groupServices.restoreGroup(groupId, userData.id);
    if (data === false) {
      console.log("Something went wrong.");
    } else {
      console.log(data.code);
      if (data.code === 200) {
        getGroups();
      } else {
        console.log("Failed to restore group.");
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "fixed",
        width: "100%",
        height: "100vh",
      }}
    >
      <Grid container>
        <Grid
          item
          sx={{
            width: "12.5%",
            "@media screen and (max-width: 1250px)": {
              width: "20%",
            },
            "@media screen and (max-width: 750px)": {
              width: "30%",
            },
            "@media screen and (max-width: 500px)": {
              position: "absolute",
              bgcolor: "white",
              zIndex: 999,
              width: openMenu ? "60%" : "0",
              transition: "0.4s",
            },
          }}
        >
          {/* Sidebar */}
          <Box
            sx={{
              backgroundColor: "FFF",
              boxShadow: 5,
              height: "94vh",
              overflowY: "auto",
            }}
          >
            <Box padding={"10px"}>
              <Tabs
                orientation="vertical"
                value={tabValue}
                onChange={handleChangeTab}
              >
                <Tab
                  label="My Workspace"
                  icon={<WorkspacesIcon />}
                  iconPosition="start"
                  value={0}
                  sx={tabStyle}
                />

                <Divider sx={{ my: 1 }} />

                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Teams
                </Typography>
                {groupRender
                  ? activeGroups.map((group, index) => (
                      <Tab
                        icon={<GroupsIcon />}
                        iconPosition="start"
                        label={validateTabLabel(group.name)}
                        value={group.id}
                        sx={tabStyle}
                      />
                    ))
                  : null}

                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: "15px",
                    color: "black",
                    boxShadow: 3,
                    my: 2,
                    width: "100%",
                  }}
                  onClick={handleOpenTeamModal}
                >
                  <AddIcon fontSize="small" />
                  Create Team
                </Button>

                <Divider sx={{ my: 1 }} />
                <Tab
                  icon={<DeleteRoundedIcon />}
                  iconPosition="start"
                  label="Trash"
                  value={-1}
                  sx={tabStyle}
                />
              </Tabs>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          sx={{
            width: "87.5%",
            "@media screen and (max-width: 1250px)": {
              width: "80%",
            },
            "@media screen and (max-width: 750px)": {
              width: "70%",
            },
            "@media screen and (max-width: 500px)": {
              width: "100%",
            },
          }}
        >
          <Box
            sx={{
              display: "block",
              px: "5%",
              height: "100vh",
              overflowY: "auto",
            }}
          >
            {!showGroupInfo ? (
              <Grid>
                {/* Rendering tasks */}
                <Box sx={{ display: "flex", my: 1 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      my: "10px",
                      borderBottom: "3px solid grey",
                      width: "fit-content",
                      borderRadius: "5px",
                    }}
                  >
                    {tabValue === 0
                      ? "My Workspace"
                      : tabValue === -1
                      ? "Trash"
                      : activeGroups.find((group) => group.id === tabValue)
                          .name}
                  </Typography>

                  <Tooltip title="Team Information">
                    <Button
                      sx={{
                        ml: "auto",
                        borderRadius: "20px",
                        display: tabValue > 0 ? "" : "none",
                      }}
                      onClick={() => {
                        setShowGroupInfo(true);
                      }}
                    >
                      <SettingsRoundedIcon
                        fontSize="medium"
                        sx={{ color: "black" }}
                      />
                    </Button>
                  </Tooltip>
                </Box>
                {tabValue !== -1
                  ? taskRender &&
                    tasks.map((task, index) => (
                      <TaskInfo
                        task={task}
                        user={userList.find((user) => user.id === task.userId)}
                        onUpdate={() => {
                          setOpen(true);
                          setIsCreateAction(false);
                          setTask(task);
                          setTitle(task.title);
                          setDescription(task.description);
                          setSelectedMember(
                            memberList.find(
                              (member) => member.id === task.userId
                            )
                          );
                        }}
                        onChange={() => setTriggerEffect(!triggerEffect)}
                      />
                    ))
                  : inActiveGroups.map((group, index) => (
                      <Accordion
                        key={group.id}
                        sx={{
                          boxShadow: 5,
                          borderRadius: "10px",
                          mb: "10px",
                        }}
                      >
                        <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
                          {group.name}
                        </AccordionSummary>
                        <AccordionActions>
                          <Button onClick={() => restoreGroup(group.id)}>
                            Restore
                          </Button>
                        </AccordionActions>
                      </Accordion>
                    ))}

                {/* Button to add new task */}
                <Grid
                  sx={{
                    position: "fixed",
                    bottom: "5%",
                    right: "5%",
                    zIndex: "999",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "50%",
                      backgroundColor: "#0096FF	",
                      color: "#fff",
                      height: "65px",
                      width: "65px",
                      padding: 0,
                      boxShadow: 5,
                      display: tabValue === -1 ? "none" : "",
                      "&:hover": {
                        backgroundColor: "#008080",
                      },
                    }}
                    onClick={handlePlusButtonClick}
                  >
                    <AddIcon fontSize="large" />
                  </Button>
                </Grid>

                <Grid
                  sx={{
                    position: "fixed",
                    width: "12.5%",
                    bottom: 15,
                    left: 5,
                    zIndex: "999",
                    color: "black",
                    width: "12.5%",
                    "@media screen and (max-width: 1250px)": {
                      width: "20%",
                    },
                    "@media screen and (max-width: 750px)": {
                      width: "30%",
                    },
                    "@media screen and (max-width: 500px)": {
                      width: openMenu ? "60%" : "0",
                      transition: "0.4s",
                    },
                  }}
                >
                  <Tabs>
                    <Tab
                      icon={<Logout />}
                      iconPosition="start"
                      label="Logout"
                      sx={tabStyle}
                      onClick={() => navigate("/login")}
                    />
                  </Tabs>
                </Grid>
              </Grid>
            ) : (
              <GroupInfo
                group={activeGroups.find((group) => group.id === tabValue)}
                userId={userData.id}
                userList={userList}
                onBack={(value) => {
                  setTabValue((prevState) => (value ? 0 : prevState));
                  setShowGroupInfo(false);
                  setTriggerEffect(!triggerEffect);
                }}
                onUpdate={() => {
                  getGroups();
                  setTriggerEffect(!triggerEffect);
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      {/* Modal for adding/editing task */}
      <Modal open={open} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            boxShadow: 10,
            width: "400px",
            borderRadius: "20px",
            "@media screen and (max-width: 1250px)": {
              width: "40%",
            },
            "@media screen and (max-width: 750px)": {
              width: "50%",
            },
            "@media screen and (max-width: 500px)": {
              width: "95%",
            },
          }}
        >
          <Box
            sx={{
              padding: "10px",
              py: 3,
              textAlign: "center",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold", pb: "7%" }}>
              Add your task here
            </Typography>
            <CustomTextField
              fullWidth
              sx={{ display: "block", mb: "10%" }}
              label="Enter Title of the Task"
              value={title}
              onChange={handleEnterTaskTitle}
            />
            <CustomTextField
              fullWidth
              multiline
              maxRows={4}
              sx={{ display: "block", mb: "10%" }}
              label="Enter Task Description"
              value={description}
              onChange={handleEnterTaskDescription}
            />

            {tabValue !== 0 && (
              <Autocomplete
                sx={{ width: "100%", mb: "10%" }}
                id=""
                {...memberProps}
                value={selectedMember}
                onChange={(event, value) => {
                  setSelectedMember(value);
                }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    label="Select Collaborators"
                    // error={Boolean(genderError)}
                    // helperText={genderError}
                  />
                )}
                renderOption={(props, option) => (
                  <Box
                    sx={{
                      fontSize: 14,
                      fontFamily: "inherit",
                    }}
                    {...props}
                  >
                    {option.name} - {option.username} - {option.email}
                  </Box>
                )}
              />
            )}

            {/* Button to create task */}
            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
                borderRadius: "10px",
                fontSize: "16px",
              }}
              onClick={
                isCreateAction
                  ? handleCreateTaskButtonClick
                  : handleUpdateTaskButtonClick
              }
            >
              {isCreateAction ? "Create Task" : "Update Task"}
            </Button>
          </Box>

          {/* <Box
            sx={{
              padding: "10px",
              pt: "100px",
              pb: "50px",
              textAlign: "center",
              display: isCreateAction ? "none" : "",
            }}
          >
            <Typography
              sx={{ fontWeight: "bold", fontSize: "20px", pb: "10%" }}
            >
              Add your task here
            </Typography>
            <CustomTextField
              fullWidth
              sx={{ display: "block", mb: "10%" }}
              label="Enter Task Description"
              value={description}
              onChange={handleEnterTask}
            />
            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
                borderRadius: "10px",
                fontSize: "16px",
                mt: "40px",
              }}
              onClick={handleUpdateTaskButtonClick}
            >
              Update Task
            </Button>
          </Box> */}
        </Box>
      </Modal>

      {/* Modal for adding team */}
      <Modal open={openTeamModal} onClose={handleCloseTeamModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            boxShadow: 10,
            width: "400px",
            borderRadius: "20px",
            "@media screen and (max-width: 1250px)": {
              width: "40%",
            },
            "@media screen and (max-width: 750px)": {
              width: "50%",
            },
            "@media screen and (max-width: 500px)": {
              width: "95%",
            },
          }}
        >
          <Box
            sx={{
              padding: "10px",
              py: 3,
              textAlign: "center",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold", pb: "7%" }}>
              Add Team
            </Typography>

            <CustomTextField
              fullWidth
              sx={{ display: "block", mb: "10%" }}
              label="Enter Team Name"
              value={teamName}
              onChange={handleEnterTeamName}
            />

            <Autocomplete
              sx={{ width: "100%" }}
              id=""
              multiple
              {...userProps}
              value={selectedUsers}
              onChange={(event, value) => {
                setSelectedUsers(value);
              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  label="Select Collaborators"
                  // error={Boolean(genderError)}
                  // helperText={genderError}
                />
              )}
              renderOption={(props, option) => (
                <Box
                  sx={{
                    fontSize: 14,
                    fontFamily: "inherit",
                  }}
                  {...props}
                >
                  {option.name} {option.username} {option.email}
                </Box>
              )}
            />

            <Button
              variant="contained"
              disabled={isDisabled}
              sx={{
                textTransform: "capitalize",
                borderRadius: "10px",
                fontSize: "16px",
                mt: "40px",
              }}
              onClick={() => {
                createGroup();
                setTriggerEffect(!triggerEffect);
              }}
            >
              Create Team
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default ToDo_Body;
