import { Box, Button, Grid, IconButton, TextField } from "@mui/material";
import React, { useState } from "react";
import CustomTextField from "../GenericComponents/CustomTextField";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import userServices from "../Services/UserServices";
import { useNavigate } from "react-router-dom";

function UserLogin() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (username.length === 0) {
      console.log("Username is required.");
    } else if (password.length === 0) {
      console.log("Password is required");
    } else {
      const auth = {
        username: username,
        password: password,
      };
      localStorage.setItem("auth", JSON.stringify(auth));
      let data = await userServices.validateUser();
      if (data === false) {
        console.log("Something went wrong.");
      } else {
        console.log(data.code);
        if (data.code === 200) {
          console.log("Login Successful.");
          localStorage.setItem("isLoggedIn", "true");
          // localStorage.setItem("userData", JSON.stringify(data.responseBody));
          console.log(data.responseBody);
          navigate("/dashboard", { state: data.responseBody });
        } else {
          console.log("Invalid user credentials.");
        }
      }
    }
  };

  return (
    <div>
      <Box
        sx={{
          position: "absolute",
          display: "grid",
          gap: 5,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 5,
          borderRadius: 5,
          p: 2,
          px: 5,
          width: "30%",
          "@media screen and (max-width: 1250px)": {
            width: "40%",
          },
          "@media screen and (max-width: 750px)": {
            width: "80%",
          },
          "@media screen and (max-width: 500px)": {
            width: "60%",
            gap: 0,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            "@media screen and (max-width: 500px)": {
              display: "block",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              borderRight: 1,
              p: 2,
              width: "50%",
              "@media screen and (max-width: 500px)": {
                width: "90%",
                alignItems: "center",
                borderRight: 0,
                borderBottom: 1,
              },
            }}
          >
            <img
              src="/assets/logo.png"
              alt="Login"
              style={{
                width: "90%",
                boxShadow: "1px 1px 0.5px 0.5px lightgrey",
                "@media screen and (max-width: 500px)": {
                  width: "200px",
                },
              }}
            />
          </Box>

          <Box
            sx={{
              display: "grid",
              gap: 2,
              pl: 2,
              my: 3,
              width: "50%",
              "@media screen and (max-width: 500px)": {
                width: "100%",
                p: 0,
              },
            }}
          >
            <CustomTextField
              id=""
              label="User Name"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />

            <CustomTextField
              id=""
              label="Password"
              InputProps={{
                style: {
                  borderColor: "#FFFFF",
                  backgroundColor: "#F5F5F5",
                  color: "#000000",
                },
                endAdornment: (
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}{" "}
                    {/* Eye icon */}
                  </IconButton>
                ),
              }}
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button variant="contained" onClick={handleLogin}>
              Login
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default UserLogin;
