import React, { useState } from "react";
import ToDo_Navbar from "./ToDo_Navbar";
import ToDo_Body from "./Todo_Body";
import { useLocation } from "react-router-dom";

function ToDoModule() {
  const location = useLocation();
  const state = location.state;

  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuClick = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <div>
      <ToDo_Navbar userData={state} onMenuClick={handleMenuClick} />
      <ToDo_Body
        userData={state}
        openMenu={openMenu}
        onTabChange={handleMenuClick}
      />
    </div>
  );
}

export default ToDoModule;
