import axios from "axios";
import { apiEndpoint } from "../config";

let axiosConfig = {
  withCredentials: true,
};

class ToDoServices {
  async addTask(data) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/todotask/add`,
        data,
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }

  async updateTask(data) {
    try {
      const response = await axios.put(
        `${apiEndpoint}/todotask/update`,
        data,
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }

  async getPersonalTasks(userId) {
    try {
      const response = await axios.get(
        `${apiEndpoint}/todotask/list/${userId}`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }

  async getGroupTasks(groupId) {
    try {
      const response = await axios.get(
        `${apiEndpoint}/todotask/list?groupId=${groupId}`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }

  async deleteTask(taskId) {
    try {
      const response = await axios.delete(
        `${apiEndpoint}/todotask/${taskId}`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }
}

const toDoServices = new ToDoServices();
export default toDoServices;
