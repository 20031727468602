import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import ToDoModule from "./TODO_Module/ToDoModule";
import UserLogin from "./Auth/UserLogin";
import SignUp from "./Auth/SignUp";
import Home from "./Home";

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route path="/login" element={<UserLogin />} />
            <Route path="/signup" element={<SignUp />} />
          </Route>
          <Route path="/dashboard" element={<ToDoModule />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
