import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Modal,
  Tab,
  Tabs,
  TextField,
  Typography,
  tableSortLabelClasses,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import toDoServices from "../Services/ToDoServices";
import { Link, Divider } from "@mui/material";
import {
  AddBoxRounded,
  ConstructionOutlined,
  TaskAlt,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import groupServices from "../Services/GroupServices";
import CustomTextField from "../GenericComponents/CustomTextField";
import TaskStatus from "../Enums/TaskStatus";

function TaskInfo({ task, user, onUpdate, onChange }) {
  // State for modal visibility
  const [open, setOpen] = useState(false);
  // State for task title in modal
  const [title, setTitle] = useState("");
  // State for task description in modal
  const [description, setDescription] = useState("");
  // State for creating or editing a task
  const [isCreateAction, setIsCreateAction] = useState(false);

  const [triggerEffect, setTriggerEffect] = useState(false);

  const completeTask = async () => {
    try {
      let newStatus =
        task.status === TaskStatus.DONE
          ? TaskStatus.IN_PROGRESS
          : TaskStatus.DONE;
      let data = await toDoServices.updateTask({
        id: task.id,
        title: task.title,
        description: task.description,
        status: newStatus,
        date: task.date,
        userId: task.userId,
        groupId: task.groupId,
      });
      console.log(data);
      if (data === false) {
        console.log("Something went wrong.");
      } else {
        if (data.code === 200) {
          console.log("Task marked as Done ", data.responseBody);
        }
      }
    } catch (error) {
      console.log("ERROR");
    }
  };

  // Function to handle marking a task as complete or incomplete
  const handleToggleComplete = () => {
    completeTask();
    onChange();
  };

  async function updateTask() {
    try {
      const currentTimeStamp = new Date();
      const currentDate = currentTimeStamp.toISOString().split("T")[0];
      let data = await toDoServices.updateTask({
        id: task.id,
        title: title,
        description: description,
        status: task.status,
        date: currentDate,
        userId: task.userId,
        groupId: task.groupId,
      });
      console.log(data);
      if (data === false) {
        console.log("Something went wrong.");
      } else {
        if (data.code === 200) {
          console.log("Task edited successfully ", data.responseBody);
        }
      }
    } catch (error) {
      console.log("ERROR");
    }
  }

  // Function to handle edit a task
  const handleUpdateTaskButtonClick = (e) => {
    updateTask();
    setOpen(false);
    onChange();
  };

  async function deleteTask(id) {
    try {
      let data = await toDoServices.deleteTask(id);
      console.log(data);
      if (data === false) {
        console.log("Something went wrong.");
      } else {
        if (data.code === 200) {
          console.log("Task deleted successfully ", data.responseBody);
          setTriggerEffect(true);
        }
      }
    } catch (error) {
      console.log("ERROR");
    }
  }

  // Function to handle deleting a task
  const handleDeleteTask = (id) => {
    deleteTask(id);
    onChange();
  };

  // Function to handle closing modal
  const handleCloseModal = (e) => {
    setOpen(false);
    setDescription("");
  };

  // Function to handle typing in task description input
  const handleEnterTaskTitle = (e) => {
    setTitle(e.target.value);
  };

  // Function to handle typing in task description input
  const handleEnterTaskDescription = (e) => {
    setDescription(e.target.value);
  };

  return (
    <div>
      <Accordion
        key={task.id}
        sx={{
          boxShadow: 5,
          borderRadius: "10px",
          mb: "10px",
          bgcolor: task.status === TaskStatus.DONE ? "#f0f0f0" : "inherit",
        }}
      >
        <AccordionSummary
          sx={{ borderRadius: "0", bgcolor: "lightyellow" }}
          expandIcon={<GridExpandMoreIcon />}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              m: "-10px",
            }}
          >
            {/* Checkbox to mark task as complete or incomplete */}
            <Checkbox
              onClick={(e) => {
                e.stopPropagation();
                handleToggleComplete();
              }}
              checked={task.status === TaskStatus.DONE ? true : false}
            />
            {/* Text for task description */}
            <Typography
              sx={{
                textDecoration:
                  task.status === TaskStatus.DONE ? "line-through" : "none",
                color: task.status === TaskStatus.DONE ? "red" : "inherit",
              }}
            >
              {task.title}
            </Typography>

            <Box sx={{ ml: "auto" }}>
              <Tooltip
                title={user.name}
                sx={{
                  p: 0,
                }}
              >
                <IconButton sx={{ p: 0 }}>
                  <Avatar>
                    {user.name.split(" ").length === 1
                      ? user.name.split(" ")[0][0]
                      : user.name.split(" ")[0][0] + user.name.split(" ")[1][0]}
                  </Avatar>
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ py: 0 }}>
          {/* <Typography>{task.description}</Typography> */}
          <CustomTextField
            multiline
            maxRows={5}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
            }}
            value={task.description}
          />
        </AccordionDetails>
        <AccordionActions>
          {/* Button to edit task */}
          <Button
            onClick={(e) => {
              // setIsCreateAction(false);
              // setOpen(true);
              // setDescription(task.description);
              // setTitle(task.title);
              onUpdate();
            }}
          >
            Edit
          </Button>
          {/* Button to delete task */}
          <Button onClick={() => handleDeleteTask(task.id)}>Delete</Button>
        </AccordionActions>
      </Accordion>

      {/* Modal for adding/editing task */}
      <Modal open={open} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            boxShadow: 10,
            width: "400px",
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              padding: "10px",
              pt: "100px",
              pb: "50px",
              textAlign: "center",
              display: isCreateAction ? "none" : "",
            }}
          >
            <Typography
              sx={{ fontWeight: "bold", fontSize: "20px", pb: "10%" }}
            >
              Add your task here
            </Typography>

            <CustomTextField
              fullWidth
              sx={{ display: "block", mb: "10%" }}
              label="Enter Title of the Task"
              value={title}
              onChange={handleEnterTaskTitle}
            />

            <CustomTextField
              fullWidth
              multiline
              maxRows={4}
              sx={{ display: "block", mb: "10%" }}
              label="Enter Task Description"
              value={description}
              onChange={handleEnterTaskDescription}
            />
            {/* Button to create task */}
            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
                borderRadius: "10px",
                fontSize: "16px",
                mt: "40px",
              }}
              onClick={handleUpdateTaskButtonClick}
            >
              Update Task
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default TaskInfo;
