import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  ImageListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import Logout from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";

function ToDo_Navbar({ userData, onMenuClick }) {
  // const navItems = navBarLinks.navbar_items;
  const [addQuestionDisabled, setAddQuestionDisabled] = useState(true);
  const [viewQuestionsDisabled, setViewQuestionsDisabled] = useState(true);

  // const [userData, setUserData] = useState(null);
  const userInitial = userData.name != null ? userData.name[0] : "O";

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profileText, setProfileText] = useState("Profile");
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  // useEffect(() => {
  //   if (currentPath === "/home/add-question") {
  //     setAddQuestionDisabled(true);
  //     setViewQuestionsDisabled(false);
  //   } else if (currentPath === "/home/view-questions") {
  //     setAddQuestionDisabled(false);
  //     setViewQuestionsDisabled(true);
  //   } else {
  //     setAddQuestionDisabled(false);
  //     setViewQuestionsDisabled(false);
  //   }
  // }, [currentPath]);

  // useEffect(() => {
  //   setUserData(JSON.parse(localStorage.getItem("userData")).name);
  // }, []);

  useEffect(() => {
    setProfileText(window.innerWidth > 500 ? "Profile" : userData.name);
  }, [window.innerWidth]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userData");
    localStorage.removeItem("auth");
    navigate("/login");
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "6vh",
      }}
    >
      {/* <CssBaseline /> */}
      <AppBar
        component="nav"
        sx={{
          backgroundColor: "white",
          color: "teal",
          height: "6vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container display="flex" alignItems="center">
          <Grid item display="flex" sx={{ alignItems: "center" }}>
            <Button
              sx={{
                color: "black",
                mr: -2,
                display: "none",
                "@media screen and (max-width: 500px)": {
                  display: "flex",
                },
              }}
              onClick={onMenuClick}
            >
              <MenuIcon fontSize="medium" />
            </Button>
            <ImageListItem sx={{ margin: "5px 10px 5px 15px" }}>
              <img
                src="/assets/favicon.png"
                alt="Logo"
                style={{ height: "40px", width: "40px" }}
              />
            </ImageListItem>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "24px",
              }}
            >
              ToDo-App
            </Typography>
          </Grid>

          <Grid item sx={{ position: "fixed", right: 0 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                border: "1px solid grey",
                borderRadius: "40px",
                mx: "1px",
                "@media screen and (max-width: 500px)": {
                  mx: 1,
                },
              }}
            >
              <Typography
                fontWeight="bold"
                fontSize="18px"
                textAlign="right"
                paddingLeft="15px"
                sx={{
                  width: "100%",
                  "@media screen and (max-width: 500px)": {
                    display: "none",
                  },
                }}
              >
                {userData.name}
              </Typography>
              <Tooltip title={userData.name}>
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{
                    ml: 1,
                    "@media screen and (max-width: 500px)": {
                      m: 0,
                      p: 0,
                    },
                  }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar>{userInitial}</Avatar>
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <AccountCircleIcon
                  sx={{ width: "25px", height: "25px" }}
                  fontSize="small"
                />
              </ListItemIcon>
              {profileText}
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout
                  sx={{ width: "25px", height: "25px" }}
                  fontSize="small"
                />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Grid>
      </AppBar>
    </Box>
  );
}

export default ToDo_Navbar;
