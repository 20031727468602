import React, { useEffect, useState } from "react";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import {
  Autocomplete,
  Box,
  Button,
  InputLabel,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CustomTextField from "../GenericComponents/CustomTextField";
import {
  DataGrid,
  GridActionsCellItem,
  GridDeleteIcon,
} from "@mui/x-data-grid";
import groupServices from "../Services/GroupServices";

function GroupInfo({ group, userId, userList, onBack, onUpdate }) {
  const columns = [
    {
      field: "seq",
      headerName: "SL No",
      align: "center",
      headerAlign: "center",
      flex: 0.5,
      flexGrow: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "username",
      headerName: "Username",
      align: "center",
      headerAlign: "center",
      flex: 1,
      flexGrow: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "Full Name",
      align: "center",
      headerAlign: "center",
      flex: 1,
      flexGrow: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "email",
      headerName: "Email",
      align: "center",
      headerAlign: "center",
      flex: 1,
      flexGrow: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      align: "center",
      headerAlign: "center",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
      cellClassName: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          disabled={
            params.row.id === group.owner.id || group.owner.id !== userId
          }
          icon={<GridDeleteIcon />}
          label="Delete"
          color="error"
          onClick={() => handleDeleteMember(params)}
        />,
      ],
    },
  ];

  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [availableUsers, setAvailableUsers] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const availableUserProps = {
    options: availableUsers,
    getOptionLabel: (option) => option.email,
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTeamModalClose = () => {
    setOpenTeamModal(false);
  };

  useEffect(() => {
    setRows(
      group.members.map((member, index) => {
        return {
          id: member.id,
          seq: index + 1,
          name: member.name,
          username: member.username,
          email: member.email,
        };
      })
    );
    setAvailableUsers(
      userList.filter(
        (obj1) => !group.members.some((obj2) => obj2.id === obj1.id)
      )
    );
  }, [group]);

  const handleDeleteMember = async (params) => {
    try {
      let data = await groupServices.deleteMemberFromGroup({
        groupId: group.id,
        ownerId: group.owner.id,
        memberId: params.row.id,
      });

      if (data === false) {
        console.log("Something went wrong.");
      } else {
        if (data.code === 200) {
          console.log("Member deleted successfully ", data.responseBody);
        }
      }
    } catch (error) {
      console.log("ERROR");
    }
    onUpdate();
  };

  const handleAddMembers = async () => {
    try {
      const memberIds = selectedUsers.map((user) => user.id);
      let data = await groupServices.addMemberToGroup({
        groupId: group.id,
        ownerId: group.owner.id,
        memberIds: memberIds,
      });
      if (data === false) {
        console.log("Something went wrong.");
      } else {
        if (data.code === 200) {
          console.log("Member added successfully ", data.responseBody);
        }
      }
    } catch (error) {
      console.log("ERROR");
    }
    handleTeamModalClose();
    onUpdate();
    setSelectedUsers([]);
  };

  const handleDeleteGroup = async () => {
    try {
      let data = await groupServices.deleteGroup(group.id, group.owner.id);
      if (data === false) {
        console.log("Something went wrong.");
      } else {
        if (data.code === 200) {
          console.log("Group deleted successfully ", data.responseBody);
        }
      }
    } catch (error) {
      console.log("ERROR");
    }
    onBack(true);
  };

  return (
    <div>
      <Box
        sx={{
          my: 2,
          justifyContent: "center",
          display: "inline-block",
          gap: 1,
          width: "100%",
        }}
      >
        <Button
          sx={{
            mr: "auto",
            "@media screen and (max-width: 500px)": {
              ml: -3,
            },
          }}
          onClick={() => onBack(false)}
        >
          <ArrowBackRoundedIcon fontSize="small" />
          <Typography variant="body2" fontFamily="inherit" sx={{}}>
            Dashboard
          </Typography>
        </Button>

        <Box
          sx={{
            mx: 5,
            my: 1,
            display: "grid",
            gap: 2,
            "@media screen and (max-width: 500px)": {
              m: 0,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              my: 1,
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontFamily: "inherit", fontWeight: "bold", mr: 2 }}
            >
              Team Name:
            </Typography>
            <CustomTextField sx={{ width: "fit-content" }} value={group.name} />
          </Box>

          <Box sx={{ my: 1, display: "grid", gap: 2 }}>
            <Typography
              variant="body1"
              sx={{ fontFamily: "inherit", fontWeight: "bold", mr: 2 }}
            >
              Members:
            </Typography>
            <Button
              color="success"
              sx={{ ml: "auto", display: "inline-flex" }}
              onClick={() => setOpenTeamModal(true)}
            >
              <AddIcon fontSize="small" sx={{ my: 0.5 }} />
              Add Member
            </Button>
            <DataGrid
              rows={rows}
              columns={columns}
              sx={{
                width: "100%",
                border: 1,
                "& .MuiDataGrid-cell:hover": {
                  color: "#1C3F75",
                },
                "& .super-app-theme--header": {
                  fontFamily: "inherit",
                  fontWeight: "bold",
                  backgroundColor: "#E8E8E8",
                },
              }}
            />

            <Button
              color="error"
              sx={{ mr: "auto", px: 2, py: 1 }}
              onClick={() =>
                group.owner.id === userId ? handleDeleteGroup() : setOpen(true)
              }
            >
              Delete Team
            </Button>
          </Box>
        </Box>
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            boxShadow: 10,
            width: 400,
            borderRadius: "10px",
            p: 3,
            "@media screen and (max-width: 1250px)": {
              width: "40%",
            },
            "@media screen and (max-width: 750px)": {
              width: "60%",
            },
            "@media screen and (max-width: 500px)": {
              width: "80%",
            },
          }}
        >
          <Typography fontFamily="inherit" variant="h6">
            Bhaag BSDK! Aukaat mat bhulo apni!
          </Typography>
          <Typography fontFamily="inherit" variant="body1" sx={{ my: 1 }}>
            You don't have the required permission for performing the opertion
          </Typography>
          <Button onClick={() => handleClose()}>Close</Button>
        </Box>
      </Modal>

      <Modal open={openTeamModal} onClose={handleTeamModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            boxShadow: 10,
            width: "400px",
            borderRadius: "20px",
            "@media screen and (max-width: 1250px)": {
              width: "40%",
            },
            "@media screen and (max-width: 750px)": {
              width: "60%",
            },
            "@media screen and (max-width: 500px)": {
              width: "95%",
            },
          }}
        >
          <Box
            sx={{
              padding: "10px",
              py: 3,
              textAlign: "center",
            }}
          >
            <Typography variant="h6" fontWeight="bold" sx={{ mb: "10%" }}>
              Add Members to Team
            </Typography>
            <Autocomplete
              sx={{ width: "100%" }}
              id=""
              multiple
              {...availableUserProps}
              value={selectedUsers}
              onChange={(event, value) => {
                setSelectedUsers(value);
              }}
              renderInput={(params) => (
                <CustomTextField {...params} label="Select Collaborators" />
              )}
              renderOption={(props, option) => (
                <Box
                  sx={{
                    fontSize: 14,
                    fontFamily: "inherit",
                  }}
                  {...props}
                >
                  {option.name} {option.username} {option.email}
                </Box>
              )}
            />

            <Button
              variant="contained"
              disabled={selectedUsers.length === 0}
              sx={{
                textTransform: "capitalize",
                borderRadius: "10px",
                fontSize: "16px",
                mt: "40px",
              }}
              onClick={() => handleAddMembers()}
            >
              Add Members
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default GroupInfo;
