import axios from "axios";
import { apiEndpoint } from "../config";
let axiosConfig = {
  withCredentials: true,
};

const authObj = JSON.parse(localStorage.getItem("auth"));

class GroupServices {
  async createGroup(data) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/tms/group/create`,
        data,
        {
          auth: {
            username: authObj.username,
            password: authObj.password,
          },
        }
      );
      console.log("Create Group API response=>", response);
      return response.data;
    } catch (error) {
      console.log("error ", error);
      return false;
    }
  }

  async addMemberToGroup(data) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/tms/group/member/add`,
        data,
        {
          auth: {
            username: authObj.username,
            password: authObj.password,
          },
        }
      );
      console.log("Add Member to Group API response=>", response);
      return response.data;
    } catch (error) {
      console.log("error ", error);
      return false;
    }
  }

  async deleteMemberFromGroup(data) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/tms/group/member/delete`,
        data,
        {
          auth: {
            username: authObj.username,
            password: authObj.password,
          },
        }
      );
      console.log("Delete Member from Group API response=>", response);
      return response.data;
    } catch (error) {
      console.log("error ", error);
      return false;
    }
  }

  async getGroupsByUserId(userId) {
    try {
      const response = await axios.get(
        `${apiEndpoint}/tms/group/list/${userId}`,
        {
          auth: {
            username: authObj.username,
            password: authObj.password,
          },
        }
      );
      console.log("Get Groups API response=>", response);
      return response.data;
    } catch (error) {
      console.log("error ", error);
      return false;
    }
  }

  async deleteGroup(groupId, ownerId) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/tms/group/delete/${groupId}/${ownerId}`,
        {},
        {
          auth: {
            username: authObj.username,
            password: authObj.password,
          },
        }
      );
      console.log("Add Member API response=>", response);
      return response.data;
    } catch (error) {
      console.log("error ", error);
      return false;
    }
  }

  async restoreGroup(groupId, ownerId) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/tms/group/restore/${groupId}/${ownerId}`,
        {},
        {
          auth: {
            username: authObj.username,
            password: authObj.password,
          },
        }
      );
      console.log("Restore Group API response=>", response);
      return response.data;
    } catch (error) {
      console.log("error ", error);
      return false;
    }
  }
}

const groupServices = new GroupServices();
export default groupServices;
