import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import CustomTextField from "../GenericComponents/CustomTextField";
import CustomDatePicker from "../GenericComponents/CustomDatePicker";
import userServices from "../Services/UserServices";
import { useNavigate } from "react-router-dom";

const paperStyle = { padding: "30px 20px", width: 300, margin: "20px auto" };
const headerStyle = { margin: 0 };
const marginTop = { marginTop: 4 };

function SignUp() {
  const navigate = useNavigate();

  const [name, setName] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNo, setPhoneNo] = React.useState("");
  const [gender, setGender] = React.useState(null);
  const [dob, setDOB] = React.useState(null);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [isChecked, setIsChecked] = React.useState(false);

  //Errors
  const [nameError, setNameError] = React.useState("");
  const [usernameError, setUsernameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [phoneNoError, setPhoneNoError] = React.useState("");
  const [genderError, setGenderError] = React.useState("");
  const [dobError, setDOBError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState("");

  const genderList = ["Male", "Female", "Others"];
  const genderProps = {
    options: genderList,
  };

  //Restrict All textField
  const handlenamechange = (event) => {
    const { value } = event.target; // Only allow alpha characters
    const newValue = value.replace(/[^A-Za-z ]/g, "");
    setNameError("");
    setName(newValue);
  };

  const handleUsernamechange = (event) => {
    const { value } = event.target; // Only allow alpha characters
    const newValue = value.replace(/[^A-Za-z ]/g, "");
    setUsernameError("");
    setUsername(newValue);
  };

  const handleEmailchange = (event) => {
    const { value } = event.target; // Only allow alphanumeric and Special  characters
    const newValue = value.replace(/[^A-Za-z0-9@._]/g, "");
    setEmailError("");
    setEmail(newValue);
  };

  const handlePhoneNochange = (event) => {
    const { value } = event.target;
    // Only allow numeric characters and limit to 10 characters
    const newValue = value.replace(/[^0-9]/g, "").slice(0, 10);
    setPhoneNoError("");
    setPhoneNo(newValue);
  };
  const handleGenderChange = (event, value) => {
    setGenderError("");
    setGender(value);
  };
  const handleDOBChange = (value) => {
    setDOBError("");
    setDOB(value);
  };
  const handlePasswordchange = (event) => {
    const { value } = event.target;
    setPasswordError("");
    setConfirmPasswordError(""); // Clear any previous error message
    setPassword(value); // Update the password state with the new value
  };
  const handleConfirmPasswordchange = (event) => {
    const { value } = event.target;
    setConfirmPasswordError(""); // Clear any previous error message
    setConfirmPassword(value); // Update the password state with the new value
  };

  const validateForm = () => {
    setNameError(name ? "" : "This field is required");
    setUsernameError(username ? "" : "This field is required");
    setEmailError(email ? "" : "This field is required");
    setPhoneNoError(phoneNo ? "" : "This field is required");
    setGenderError(gender != null ? "" : "This field is required");
    setDOBError(dob != null ? "" : "This field is required");
    setPasswordError(password ? "" : "This field is required");
    setConfirmPasswordError(confirmPassword ? "" : "This field is required");

    setConfirmPasswordError(
      password === confirmPassword ? "" : "Both passwords are not matching"
    );

    const isFormValid =
      name &&
      username &&
      email &&
      phoneNo &&
      gender &&
      dob != null &&
      password &&
      confirmPassword &&
      password === confirmPassword;
    return isFormValid;
  };

  const handleSignUp = async () => {
    if (validateForm()) {
      const formattedDOB = dob.format("DD-MM-YYYY");
      const jsonData = {
        user: {
          name: name,
          username: username,
          mobile: phoneNo,
          email: email,
          gender: gender,
          dob: formattedDOB,
          password: password,
        },
        authorities: [],
      };

      let data = await userServices.registerUser(jsonData);

      if (data === false) {
        console.log("Something went wrong.");
      } else {
        if (data.code === 200) {
          console.log("User Added Successfully. ", data.responseBody);
          navigate("/login");
        } else {
          console.log("Invalid user details.");
        }
      }
    }
  };

  return (
    <Grid>
      <Paper
        elevation={20}
        sx={{
          position: "absolute",
          display: "grid",
          gap: 2,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 5,
          borderRadius: 5,
          p: 2,
          px: 5,
          width: "30%",
          "@media screen and (max-width: 1250px)": {
            width: "40%",
          },
          "@media screen and (max-width: 750px)": {
            width: "60%",
          },
          "@media screen and (max-width: 500px)": {
            width: "80%",
            my: 5,
          },
        }}
      >
        <Typography
          textAlign="center"
          variant="h4"
          fontWeight="bold"
          fontFamily="inherit"
          style={headerStyle}
        >
          Sign Up
        </Typography>
        <Typography
          textAlign="center"
          variant="caption"
          fontFamily="inherit"
          gutterBottom
        >
          Please fill this form to create an account !
        </Typography>

        <CustomTextField
          fullWidth
          required
          variant="standard"
          id="name"
          label="Full Name"
          value={name}
          onChange={handlenamechange}
          error={Boolean(nameError)}
          helperText={nameError}
          onBlur={() => {
            setNameError(name ? "" : "This field is required");
          }}
        />

        <CustomTextField
          fullWidth
          required
          variant="standard"
          id="name"
          label="Enter User Name"
          value={username}
          onChange={handleUsernamechange}
          error={Boolean(usernameError)}
          helperText={usernameError}
          onBlur={() => {
            setUsernameError(username ? "" : "This field is required");
          }}
        />

        <CustomTextField
          fullWidth
          required
          id="email"
          label="E-mail"
          type="email"
          variant="standard"
          value={email}
          onChange={handleEmailchange}
          error={Boolean(emailError)}
          helperText={emailError}
          onBlur={() => {
            setEmailError(email ? "" : "This field is required");
          }}
        />
        <CustomTextField
          fullWidth
          required
          id="phoneNo"
          label="Phone Number"
          variant="standard"
          value={phoneNo}
          onChange={handlePhoneNochange}
          error={Boolean(phoneNoError)}
          helperText={phoneNoError}
          onBlur={() => {
            setPhoneNoError(phoneNo ? "" : "This field is required");
          }}
        />

        <Autocomplete
          sx={{ width: "100%" }}
          id=""
          required
          disableClearable
          {...genderProps}
          value={gender}
          onChange={handleGenderChange}
          onBlur={() => {
            setGenderError(gender != null ? "" : "This field is required");
          }}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              label="Gender"
              variant="standard"
              error={Boolean(genderError)}
              helperText={genderError}
            />
          )}
          renderOption={(props, option) => (
            <Typography
              sx={{
                fontSize: 14,
                fontFamily: "inherit",
              }}
              {...props}
            >
              {option}
            </Typography>
          )}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CustomDatePicker
            id="dob"
            required
            format="DD-MM-YYYY"
            value={dob}
            label="Date of Birth"
            sx={{ width: "100%" }}
            onChange={handleDOBChange}
            onBlur={() => {
              setDOBError(dob != null ? "" : "This field is required");
            }}
            slotProps={{
              textField: {
                required: true,
                variant: "standard",
                helperText: dobError,
                error: Boolean(dobError),
              },
            }}
          />
        </LocalizationProvider>

        <CustomTextField
          fullWidth
          required
          id="password"
          label="Password"
          placeholder="Enter your password"
          type="password"
          variant="standard"
          value={password}
          onChange={handlePasswordchange}
          error={Boolean(passwordError)}
          helperText={passwordError}
          onBlur={() => {
            setPasswordError(password ? "" : "This field is required");
          }}
        />
        <CustomTextField
          fullWidth
          required
          id="ConfirmPassword"
          label=" Confirm Password"
          placeholder="Confirm your password"
          type="password"
          variant="standard"
          value={confirmPassword}
          onChange={handleConfirmPasswordchange}
          error={Boolean(confirmPasswordError)}
          helperText={confirmPasswordError}
          onBlur={() => {
            setConfirmPasswordError(
              confirmPassword ? "" : "This field is required"
            );
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="checkedA"
              checked={isChecked}
              onChange={(e) => {
                setIsChecked(e.target.checked);
              }}
            />
          }
          label="I accept the terms and conditions."
        />
        <Button
          disabled={!isChecked}
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSignUp}
        >
          Sign up
        </Button>
      </Paper>
    </Grid>
  );
}
export default SignUp;
