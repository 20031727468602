import axios from "axios";
import { apiEndpoint } from "../config";

let axiosConfig = {
  withCredentials: true,
};

class UserServices {
  // Verify login when login button is clicked afrer providing username and password
  async validateUser() {
    try {
      const authObj = JSON.parse(localStorage.getItem("auth"));
      const response = await axios.post(
        `${apiEndpoint}/tms/user/login`,
        {},
        {
          auth: {
            username: authObj.username,
            password: authObj.password,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error ", error);
      return false;
    }
  }

  async getAllUsers() {
    try {
      const authObj = JSON.parse(localStorage.getItem("auth"));
      let response = await axios.get(`${apiEndpoint}/tms/user/list`, {
        auth: {
          username: authObj.username,
          password: authObj.password,
        },
      });
      return response.data;
    } catch (error) {
      console.log("error ", error);
      return false;
    }
  }

  async registerUser(data) {
    try {
      let response = await axios.post(
        `${apiEndpoint}/tms/user/add`,
        data,
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.log("error ", error);
      return false;
    }
  }
}

const userServices = new UserServices();
export default userServices;
